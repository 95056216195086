import React from 'react';
import ReactDOM from 'react-dom/client';



import { RouterProvider } from 'react-router-dom';
import router from './api/router';
import { ContextProvider } from './api/ContextProvider';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>
  </React.StrictMode>
);
