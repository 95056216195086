import axios from 'axios';
import { useStateContext} from './ContextProvider';

const axiosClient = axios.create({ 
    
    baseURL: "http://localhost:8000/api"

})


axiosClient.interceptors.request.use( (config) => {    
    const token = localStorage.getItem('ACCESS_TOKEN');

    config.headers.Accept = 'application/json'
    config.headers.Authorization = `Bearer ${token}`
    config.headers.ContentType = 'multipart/form-data, application/x-www-form-urlencoded, text/plain'

    return config;
})


axiosClient.interceptors.response.use( (response) => {
    return response
}, (error) =>{
    const {response} = error ;

    if(response.success === false ){
        localStorage.removeItem('ACCESS_TOKEN')
    }
    throw error;


} )


export default axiosClient;
