import { TextField } from "@mui/material";
import React from "react";





export const CustomTextField = (  ) =>{
    return (
        <>

               
        </>
            )
}
export default CustomTextField;



export const NewTextField = ( { label, value, type, required, onChange } ) =>{
    return (
        <>
            <TextField
                label={label}
                value= {value}

                onChange={onChange}                            
                
                type= {type}
                required={required}
                focused 
                fullWidth 
                margin="normal" 
                variant='outlined'
                color='success'                           
                
            />  
       
        </>
            )

}






export const UpdateTextField = ( { label, value, type, required, onChange } ) =>{
    return (
        <>
            <TextField
                label={label}
                value= {value}

                onChange={onChange}                            
                
                type= {type}
                required={required}
                
                focused 
                fullWidth 
                margin="normal" 
                variant='outlined'
                color='warning'                           
                
            />  
       
        </>
            )

}









export const ViewTextField = ( { label, value, type, required, onChange } ) =>{
    return (
        <>
            <TextField
                label={label}
                value= {value}

                onChange={onChange}                            
                
                type= {type}
                required={required}
                
                focused 
                fullWidth 
                margin="normal" 
                variant='outlined'
                color='info'                           
                
            />  
       
        </>
            )

}







export const DeleteTextField = ( { label, value, type, required, onChange } ) =>{
    return (
        <>
            <TextField
                label={label}
                value= {value}

                onChange={onChange}                            
                
                type= {type}
                required={required}
                
                focused 
                fullWidth 
                margin="normal" 
                variant='outlined'
                color='error'                           
                
            />  
       
        </>
            )

}







