import React from "react";

import  Button from "@mui/material/Button"


import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ReplyIcon from '@mui/icons-material/Reply';
import CommentIcon from '@mui/icons-material/Comment';
import LockResetIcon from '@mui/icons-material/LockReset';





import SpeakerNotesSharpIcon from '@mui/icons-material/SpeakerNotesSharp';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';
import AddCommentSharpIcon from '@mui/icons-material/AddCommentSharp';
import RateReviewSharpIcon from '@mui/icons-material/RateReviewSharp';







import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';









import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';












import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';



import { Box, Paper } from '@mui/material';



import InfoIcon from '@mui/icons-material/Info';

import LoginIcon from '@mui/icons-material/Login';



///////////////////////////////////////////////////////////////////////////
export const CustomHeader = ( {} ) =>{
    return (
        <>
       
        </>
            )
}
export default CustomHeader
///////////////////////////////////////////////////////////////////////////





//////////////////////////////////////////////////////////////////////////
export const ListHeader = ({header}) =>{
    return (
        <>
           
           <Box
        display= 'flex'
        justifyContent="center"
        alignItems="center"
        sx={{ 
            p:2,
            columnGap: 2,            
            }}
        >
           
            <NotificationsActiveIcon 
            color= 'secondary'
            sx={{ fontSize: 50 }}           
            />
            <h1> 
            {header}
          </h1>
        </Box>
            
        
        
        </>
            )

}
//////////////////////////////////////////////////////////////////////////////














////////////////////////////////////////////////////////////////////////////
export const NewHeader = ({header}) =>{
    return (
        <>
           
           <Box
        display= 'flex'
        justifyContent="center"
        alignItems="center"
        sx={{ 
            p:2,
            columnGap: 2,            
            }}
        >
           
            <NotificationAddIcon 
            color= 'success'
            sx={{ fontSize: 50 }}           
            />
            <h1> 
            {header}
          </h1>
        </Box>
            
        
        
        </>
            )

}
/////////////////////////////////////////////////////////////////////////////////









////////////////////////////////////////////////////////////////////////////////
export const ViewHeader = ({header}) =>{
    return (
        <>
           
           <Box
        display= 'flex'
        justifyContent="center"
        alignItems="center"
        sx={{ 
            p:2,
            columnGap: 2,            
            }}
        >
           
            <NotificationImportantIcon 
            color= 'info'
            sx={{ fontSize: 50 }}           
            />
            <h1> 
            {header}
          </h1>
        </Box>
            
        
        
        </>
            )

}
//////////////////////////////////////////////////////////////////////////////////////









/////////////////////////////////////////////////////////////////////////////////////
export const UpdateHeader = ({header}) =>{
    return (
        <>
           
           <Box
        display= 'flex'
        justifyContent="center"
        alignItems="center"
        sx={{ 
            p:2,
            columnGap: 2,            
            }}
        >
           
            <EditNotificationsIcon 
            color= 'warning'
            sx={{ fontSize: 50 }}           
            />
            <h1> 
            {header}
          </h1>
        </Box>
            
        
        
        </>
            )

}
//////////////////////////////////////////////////////////////////////////////////





///////////////////////////////////////////////////////////////////////////////////////
export const DeleteHeader = ({header}) =>{
    return (
        <>
           
           <Box
        display= 'flex'
        justifyContent="center"
        alignItems="center"
        sx={{ 
            p:2,
            columnGap: 2,            
            }}
        >
           
            <NotificationsOffIcon 
            color= 'error'
            sx={{ fontSize: 50 }}           
            />
            <h1> 
            {header}
          </h1>
        </Box>
            
        
        
        </>
            )

}
/////////////////////////////////////////////////////////////////////////////////////










