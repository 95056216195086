import MaterialTable from '@material-table/core'
import tableIcons from "../../components/material/MaterialTableIcons";

import * as React from 'react';





export const CustomTable = ( {} ) =>{
    return (
        <>
       
        </>
            )

}
export default CustomTable




export const ListTable = ( {title, columns, data} ) =>{
    return (
        <>
        
        <MaterialTable 
          title = {title}          
          columns= {columns}
          data ={data}
         

          icons={tableIcons}
          options={
            { search:true, 
              sort:true,
              exportButton:true,
              pageSizeOptions: [5, 20, 50]
            }            
          }          
           
          />


        </>
            )

}
