import { createBrowserRouter, Navigate  } from "react-router-dom";

import React from "react";


//Product
import  Products from "../pages/product/Products";

/////////////////////////////////////////////////////////////////////////////////////

const router = createBrowserRouter([
      
    {
        path:'',
        element: <Products/>
    },
    {
        path:'/',
        element: <Navigate to ="/upc/products/"/>
    },  
    {
        path:'/upc/products/',
        element: <Products/>
    },  
   

    ])



export default router;