import {useEffect, useState} from "react";
import axiosClient from "../../api/axios-client";
import {Link,useNavigate} from "react-router-dom";
import {useStateContext} from "../../api/ContextProvider";

import { AddNewButton } from "../../components/material/CustomButton";
import { ListHeader } from '../../components/material/CustomHeader';
import { ListTable } from "../../components/material/CustomTable";


import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';



//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core'
import tableIcons from "../../components/material/MaterialTableIcons";
import IconButton  from "@material-ui/core/IconButton";



import InfoIcon from '@mui/icons-material/Info';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';





import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider  from "@material-ui/core/Divider";
import Button from '@material-ui/core/Button';


import { CloseButton, SaveButton } from "../../components/material/CustomButton";
import { ViewHeader } from '../../components/material/CustomHeader';
import { ViewTextField } from '../../components/material/CustomTextField';



import { 
    Paper,
    Box,     
    Grid,
    TextField, 
    Select, 
    MenuItem,
    InputLabel, 

} from '@mui/material';



//UPC
import { ReactBarcode } from 'react-jsbarcode';















import * as React from 'react';

import { Row } from "jspdf-autotable";





export const Products = () =>{


  const [product, setProduct] = useState([]);



  const [isOpen, setOpen] = React.useState(false);

  const handleClickOpen = (rowdata) => {
    setOpen(true);
    setProduct(rowdata);

  };

  const handleClose = () => {
    setOpen(false);
  };
















  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  const {setNotification} = useStateContext()


////////////////////////////////////////////////////
  useEffect(() => {
    getProducts();
  }, [])



  const getProducts = async () =>{
    setLoading(true)
    try{    
          const response = await axiosClient.get('/upc/products/')
          setLoading(false)
          setProducts(response.data.data)
        }
      catch(err)
        {
          setLoading(false)
          setNotification(err.data)
        }
    }




  const columns = [
    {
      field:'id',
      title: 'Action',
      render: rowData => (
      <>     
         
          <Button variant="outlined" onClick={()=> handleClickOpen(rowData)} >
              View
          </Button>

      </>
      ),     
      
      

      },
     
      {
        field: 'sku',
        title: 'SKU',
      },
      {
        field: 'description', //normal accessorKey
        title: 'Description',
      },
      { field: 'media', 
            render: item => <img src={ `http://localhost:8000/images/${item.media}`} alt="" border="1" height="50" width="50" />,
        title: 'Media', },
       
        

    ]
    








    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return(
         <>

<form>
    <Paper
    elevation={15}             
    style={{
        padding: 1, 
        width: '100%',
      }}  
    >

    <div>
      
      <div style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
        <div>
        <ListHeader
              header="Products"
            />        
        </div>

               
        
      </div>
      <div > 
          <ListTable 
          
          columns={columns}
          data ={products} 
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
         
          />

            <Dialog
              fullWidth
              open={isOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              
            >
              <DialogTitle id="alert-dialog-title">
                Product Details
              </DialogTitle>
              <Divider/>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">




                <form >
    <Paper  
      elevation={15}             
      style={{
          padding: 10,  
          width: '96%',                  
        }}    
      > 
      <div >
      <ViewHeader
              header="View Product"
            />

        <Divider/>
        <div style={{ width: '100%' }}>
        
          
          <Grid 
          container 
          spacing={2}         
          >    
                      <Grid item xs={12}  sm={5}   >
                            <ViewTextField
                            label='SKU'                            
                            value={product.sku}                                                     
                            
                            required={true}
                            type= 'text'
                            
                            />  
                      </Grid>     
                      <Grid item xs={12}  sm={6}   >                        
                          <ReactBarcode value={product.upc} />
                      </Grid>

                     

                     

                      <Grid item xs={12}  sm={12}   >
                            <ViewTextField
                            label='Description'
                            value= {product.description} 
                            required={true}
                            type= 'text'   
                            />  
                      </Grid>

                      <Grid item xs={12}  sm={4}   >                        
                        <ViewTextField
                        label='Category'
                        value={product.category_name}                        
                        required={true}
                        type= 'text'                        
                        />  
                      </Grid>

                      <Grid item xs={12}  sm={4}   >                        
                        <ViewTextField
                        label='Packet Size'
                        value={product.packsize_name}                        
                        required={true}
                        type= 'text'                        
                        />  
                      </Grid>

                      <Grid item xs={12}  sm={4}   >                        
                        <ViewTextField
                        label='Multi Pack'
                        value={product.multipack_name}                        
                        required={true}
                        type= 'text'                        
                        />  
                      </Grid>


                      <Grid item xs={12}  sm={12}   >
                      <Paper>

                        <img 
                            src={ `http://localhost:8000/images/${product.id}`  }
                            alt= "Image"
                            height= "200"
                            width="515"
                            />                            
                       
                      </Paper>                       
                      </Grid>

                      
                      
          </Grid>        
          
          </div>
          <Divider/>
        <Box
        display= 'flex'
        justifyContent="center"
        alignItems="center"
        sx={{            
            
            p:2,
            columnGap: 2,
            
            }}
        >
        

          
        </Box>
      </div>

    </Paper>
    </form>
                  
                
                



                </DialogContentText>
              </DialogContent>
              <Divider/>
              {
              <DialogActions>
                <Button 
                onClick={handleClose}
                variant='contained'
                size='medium'
                startIcon={<CancelPresentationTwoToneIcon />}  
                style={{backgroundColor: '#ff0000' }}
                
                >Close</Button>
                
              </DialogActions>
              }   
            </Dialog>





           
         



      </div>
    </div>
    </Paper>
    </form>
        
    </>
    )





}

export default Products;